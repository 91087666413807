<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { formatPoints } from '/~/utils/points'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import CheckoutSummaryPayItem from './checkout-summary-pay-item.vue'

export default {
  name: 'checkout-summary-pay-with',
  components: {
    CheckoutSummaryPayItem,
  },
  props: {
    title: {
      type: String,
      default: 'Paying with',
    },
    creditCard: {
      type: Object,
      default: () => ({}),
    },
    points: {
      type: Object,
      default: () => ({}),
    },
    ewallet: {
      type: Object,
      default: () => ({}),
    },
    bankAccount: {
      type: Object,
      default: () => ({}),
    },
    coupons: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { ewalletLabels } = useCms()
    const { translate } = useLocalization()

    return {
      ewalletLabels,
      translate,
    }
  },
  computed: {
    isSelected() {
      return (
        Boolean(this.creditCard?.details) ||
        Boolean(this.points?.details) ||
        Boolean(this.ewallet?.details) ||
        Boolean(this.bankAccount?.details) ||
        this.coupons.items?.length > 0
      )
    },
    cardInfo() {
      return getCardTypeByNumber(this.creditCard.details?.number) || {}
    },
  },
  methods: {
    formatPoints,
  },
}
</script>

<template>
  <div v-if="isSelected">
    <h3 class="font-bold text-eonx-neutral-600">{{ title }}</h3>
    <div class="space-y-5 py-5">
      <template v-if="coupons.total">
        <checkout-summary-pay-item
          v-for="coupon in coupons.items"
          :key="coupon.number"
          title="Coupon"
          icon="coupon"
          icon-size="2xl"
          icon-class="text-primary"
          :details="coupon.number"
          :total="+coupon.amount"
        />
      </template>

      <checkout-summary-pay-item
        v-if="points && points.details"
        title="Points"
        icon="symbion/token"
        icon-size="2xl"
        icon-class="text-primary"
        :details="`Using ${formatPoints(points.details.usePoints)} PTS`"
        :total="points.total"
      />

      <checkout-summary-pay-item
        v-if="ewallet && ewallet.details"
        :title="ewalletLabels.ewalletCash"
        icon="plain/ewallet"
        :total="ewallet.total"
      />

      <checkout-summary-pay-item
        v-if="creditCard && creditCard.details"
        :title="`${creditCard.details.name} ${cardInfo.label}`"
        :icon="`cards/${cardInfo.type}`"
        :details="`**** **** **** ${creditCard.details.number.slice(-4)}`"
        :total="creditCard.total"
      />

      <checkout-summary-pay-item
        v-if="bankAccount && bankAccount.details"
        :title="bankAccount.details.accountName"
        icon="v2/custom/bank"
        icon-size="md"
        icon-class="text-white rounded-full bg-primary"
        :details="
          translate('bankAccount.details', {
            acc: `*** *** *${bankAccount.details.accountNumber.slice(-2)}`,
            bsb: bankAccount.details.bsb,
          })
        "
        :total="bankAccount.total"
      />
    </div>
  </div>
</template>
